import React, {PropsWithChildren, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Loader} from 'semantic-ui-react';
import {InternalError} from '@ecosio/components';
import {useQuery} from 'react-query';
import {useDispatch} from 'react-redux';
import {injectStyleSheet} from '@ecosio/auth';
import qs from 'qs';
import logger from './logger';
import {fetchMonitorConfig} from './reducers/monitorConfig';
import {fetchUserConfig} from './reducers/userConfig';
import {useAppSelector} from '@/hooks';
import {AppDispatch} from './store';

const log = logger('config-loader');

const queryOptions = {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  refetchOnMount: false
};

/**
 * TODO: tests
 *
 * This components' only job is to
 *
 * - load the userConfiguration and default monitorConfiguration on startup
 * - show a loader until all configs have been loaded
 * - show an error if the config loading failed
 *
 */
const ConfigLoader: React.FC<PropsWithChildren> = ({children}) => {
  const dispatch = useDispatch<AppDispatch>();
  const userConfig = useAppSelector(store => store.config);
  const userCompanyUuid = userConfig.userConfig.selectedSubCompany;
  const [stylesheetLoaded, setStylesheetLoaded] = useState(false);

  const {isLoading: userConfigLoading, error: userConfigErrorState} = useQuery(
    'fetch-user-config',
    () => dispatch(fetchUserConfig()),
    queryOptions
  );

  useEffect(() => {
    if (stylesheetLoaded || !userConfig?.userConfig?.theme) {
      return;
    }

    try {
      const parsedUrl = qs.parse(window.location.search, {
        ignoreQueryPrefix: true
      });
      const forceTheme = parsedUrl?.forceTheme === 'true';
      // this is only uses in sonepar iframes. We should migrate the sonepar
      // stuff to a small app
      if (forceTheme) {
        // @ts-expect-error library type mismatch
        injectStyleSheet(userConfig.userConfig);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setStylesheetLoaded(true);
    }
  }, [stylesheetLoaded, userConfig?.userConfig]);

  const configError = userConfigErrorState || userConfig.error;

  const {isLoading: monitorConfigLoading, error: monitorConfigError} = useQuery(
    `fetch-monitor-config`,
    () => dispatch(fetchMonitorConfig(userCompanyUuid)),
    // fetch the monitor config once the user has been loaded
    {...queryOptions, enabled: !!userCompanyUuid}
  );

  const loading =
    userConfigLoading || monitorConfigLoading || userConfig?.loading;

  // TODO: switch to sonepar theme if a masterdata config is loaded...
  if (loading) {
    log(
      `Rendering loader - monitorConfigLoading: ${monitorConfigLoading}, userConfigLoading: ${userConfigLoading}`
    );
    return <Loader active data-spec="app-loader" />;
    // return <AppLoader />;
  }

  if (userConfig.errorStatus === 401 || userConfig.errorStatus === 403) {
    return <Loader active data-spec="app-loader-redirect" />;
  }

  if (configError) {
    console.error(configError);
    return <InternalError />;
  }

  if (monitorConfigError) {
    console.error(monitorConfigError);
    return <InternalError />;
  }

  // typescript being weird
  return <>{children}</>;
};

ConfigLoader.propTypes = {
  children: PropTypes.node.isRequired
};

export default ConfigLoader;
