import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useRouteMatch} from 'react-router-dom';
import {useUserConfig} from '@/hooks/monitorConfigHooks';
import {setupSdk, trackPageView} from '@/helpers/analytics';

const DashboardPage = React.lazy(
  () => import('./pages/Dashboard/DashboardPage')
);

const AssemblyListPage = React.lazy(
  () => import('./pages/Masterdata/AssemblyListPage')
);

const MessageList = React.lazy(() => import('./pages/MessageList/MessageList'));

const MessageDetails = React.lazy(
  () => import('./pages/MessageDetails/MessageDetails')
);

const MessageSearchResults = React.lazy(
  () => import('./pages/MessageSearchResults/MessageSearchResults')
);

const PageNotFound = React.lazy(
  () => import('./components/ErrorBoundary/PageNotFound')
);

const UserArchiveListPage = React.lazy(
  () => import('./pages/UserArchive/UserArchiveListPage')
);

const ApiConnectorsPage = React.lazy(
  () => import('./pages/Settings/Connectors/ConnectorsPage')
);

const StatisticsPage = React.lazy(
  () => import('./pages/Statistics/StatisticsPage')
);

const PartnerMatrixPage = React.lazy(
  () => import('./pages/PartnerMatrix/PartnerMatrixPage')
);

const TicketDetailsPage = React.lazy(
  () => import('./pages/TicketDetails/TicketDetailsPage')
);

const SupportTicketsPage = React.lazy(
  () => import('./pages/SupportTicket/SupportTicketsPage')
);

const TicketEditPage = React.lazy(
  () => import('./pages/TicketDetails/TicketEditPage')
);

const TicketCreatePage = React.lazy(
  () => import('./pages/TicketDetails/TicketCreatePage')
);

const DebugPage = React.lazy(() => import('./pages/Debug'));

const MarkdownDocumentationPage = React.lazy(
  () => import('./pages/SupportTicket/Docs/MarkdownDocumentationPage')
);

const EDIRelationsPage = React.lazy(
  () => import('./pages/EDIRelations/EDIRelationsPage')
);

const SimpleEInvoiceConnectorConfigPage = React.lazy(
  () =>
    import(
      './pages/Settings/Connectors/eInvoice/SimpleEInvoiceConnectorConfigPage'
    )
);

const OAuth2EInvoiceConnectorConfigPage = React.lazy(
  () =>
    import(
      './pages/Settings/Connectors/eInvoice/OAuth2EInvoiceConnectorConfigPage'
    )
);

const Components = {
  DashboardPage,
  AssemblyListPage,
  MessageList,
  MessageDetails,
  MessageSearchResults,
  PageNotFound,
  UserArchiveListPage,
  ApiConnectorsPage,
  StatisticsPage,
  PartnerMatrixPage,
  TicketDetailsPage,
  SupportTicketsPage,
  DebugPage,
  TicketEditPage,
  TicketCreatePage,
  MarkdownDocumentationPage,
  EDIRelationsPage,
  SimpleEInvoiceConnectorConfigPage,
  OAuth2EInvoiceConnectorConfigPage
};

const CountlyWrapper = () => {
  const {path} = useRouteMatch();
  const userConfig = useUserConfig();
  const [analyticsInitialized, setAnalyticsInitialized] = useState(false);

  useEffect(() => {
    if (!analyticsInitialized) {
      return;
    }
    trackPageView(path, userConfig.company.uuid, userConfig.company.name);
  }, [
    analyticsInitialized,
    path,
    userConfig.company.uuid,
    userConfig.company.name
  ]);

  useEffect(() => {
    if (analyticsInitialized) {
      return;
    }

    setupSdk(userConfig);
    trackPageView(path, userConfig.company.uuid, userConfig.company.name);
    setAnalyticsInitialized(true);
  }, [path, userConfig, analyticsInitialized]);

  return null;
};

// TODO: jest tests
// https://sviat-kuzhelev.medium.com/fast-as-never-before-code-splitting-with-react-suspense-lazy-router-redux-webpack-4-d55a95970d11
const AsyncPage = ({componentName, ...props}) => {
  const Component = Components[componentName];

  if (!Component) {
    console.error(
      'Failed to find component',
      componentName,
      'in components object',
      Components,
      ', returning null.'
    );
    return null;
  }

  // https://gitlab.ecosio.com/code/general-issues/-/issues/464
  // https://gitlab.ecosio.com/code/monitor/-/issues/139
  return (
    <>
      <CountlyWrapper />
      <Component {...props} />
    </>
  );
};

AsyncPage.propTypes = {
  componentName: PropTypes.string.isRequired
};

export default AsyncPage;
