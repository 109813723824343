import React, {Fragment, useState} from 'react';
import {NewHeader, NewSidebar} from '@ecosio/customer-layout';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import {localesMap, switchLocale} from '@ecosio/locales';
import {connect, useDispatch} from 'react-redux';
import {logout, MODULE_TYPES, shapes} from '@ecosio/auth';
import {withRouter} from 'react-router-dom';
import qs from 'qs';
import styled from 'styled-components';

import axios from 'axios';
import {historyShape, locationShape} from '@/shapes';
import {doRedirectWhenIE} from '@/helpers/utils';
import {COMPANY_TYPE} from '@/constants';
import logger from '../../logger';
import {useAuthorities} from '@/hooks/user';
import {getAccountsBaseUrl} from '@/helpers/urls';
import SessionExpiredDialog from './SessionExpiredDialog';
import {sidebarMenuItems} from './sidebarMenuItems';
import PartnerRelationSelector from './PartnerRelationSelector';
import SelectedCompanyMismatchModal from './SelectedCompanyMismatchModal';
import NewsAndFeedback from './NewsAndFeedback/NewsAndFeedback';
import {useNewsAndFeedbackConfig} from './NewsAndFeedback/api';

const debug = logger('layout');

const inIframe = () => {
  try {
    const isInIframe = window.self !== window.top;
    debug(`Are we in an iframe? ${isInIframe}`);
    return isInIframe;
  } catch (e) {
    debug('We are in an iframe');
    return true;
  }
};

export const MonitorLayout = styled.div`
  // hack to simply hide the scrollbars when we're in an iframe
  // this is only use for sonepar/masterdata, which is considered a legacy product
  overflow: ${() => (inIframe() ? 'hidden' : 'inherit')};
`;

MonitorLayout.props = {
  isPartner: PropTypes.bool
};

/**
 * !IMPORTANT!
 *
 * We do only have 1 scenario where we don't render header/sidebar:
 *
 * Integration as iframe into the sonepar webshop.
 *
 */
export const isNoLayoutSetToTrue = query => {
  const isSetToTrue = query?.noLayout === 'true' || query?.noLayout === true;

  debug(`Will hide layout: ${isSetToTrue}`);
  return isSetToTrue;
};

const Layout = ({
  children,
  config,
  monitorConfig,
  locale,
  location,
  history
}) => {
  const dispatch = useDispatch();
  const isPartner = monitorConfig?.data?.companyType === COMPANY_TYPE.PARTNER;
  const authorities = useAuthorities();
  const {data: newsAndFeedbackConfig} = useNewsAndFeedbackConfig();
  const [newsAndFeedBackOpened, setNewsAndFeedBackOpened] = useState(false);
  const ticketDetailsEnabled =
    monitorConfig.data?.partnerMatrixTicketDetailsPageEnabled ||
    monitorConfig.data?.supportTicketDetailsPageEnabled;

  // https://gitlab.ecosio.com/code/monitor/-/issues/34
  React.useEffect(() => {
    if (!config.remoteConfigLoaded) {
      return;
    }

    const monitorModule = (config.userConfig?.modules || []).find(
      mod =>
        mod.name === MODULE_TYPES.MONITOR ||
        mod.name === MODULE_TYPES.MASTERDATA
    );

    if (!monitorModule) {
      debug('No valid module loaded');
      if (window.sessionStorage.getItem('missingModuleRedirect')) {
        debug(
          'Already redirected to auth server, logging out now to prevent a redirect loop'
        );
        dispatch(logout(axios, config.authServer));
        return;
      }
      debug('Redirecting to authServer without logging out');
      window.sessionStorage.setItem('missingModuleRedirect', 'true');
      console.warn(
        'Redirecting to accounts app, no modules configured for this company'
      );
      window.location.assign(getAccountsBaseUrl());
    }
  }, [
    config.remoteConfigLoaded,
    config.authServer,
    config.userConfig?.modules,
    dispatch
  ]);

  // Redirect back to the accounts application when using IE outside of the
  // masterdata page
  React.useEffect(() => doRedirectWhenIE(location), [location]);

  const onSwitchLocale = (e, {value}) => {
    switchLocale(value, config.authServer);
  };

  const onSearch = query => {
    if (typeof query === 'string') {
      const search = qs.stringify({
        query,
        sortField: 'lastStatusChange',
        sortOrder: 'desc'
      });
      history.push({
        pathname: '/messages/search',
        search
      });
    }
  };

  const openNewsAndFeedbackView = () => {
    setNewsAndFeedBackOpened(true);
  };

  const closeNewsAndFeedbackView = () => {
    setNewsAndFeedBackOpened(false);
  };

  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  const queryString =
    location.pathname === '/messages/search' ? query.query : undefined;

  const noLayout = isNoLayoutSetToTrue(query);

  const userConfig = {
    ...config.userConfig,
    newsFeedbackConfiguration: newsAndFeedbackConfig,
    ticketDetailsEnabled
  };
  return (
    <MonitorLayout
      id="monitor-layout"
      data-spec="layout"
      className={isPartner ? 'is-partner' : 'is-customer'}>
      <Helmet>
        <html lang={locale} />
      </Helmet>
      <SelectedCompanyMismatchModal />
      {config.userConfig?.eventSourceEnabled && (
        <SessionExpiredDialog
          key="expired-dialog"
          username={config.userConfig.email}
          authServerUrl={config.authServer}
        />
      )}
      {!noLayout ? (
        <Fragment>
          {newsAndFeedBackOpened && (
            <NewsAndFeedback
              newsFeedbackConfiguration={newsAndFeedbackConfig}
              closeViewCallbackFn={closeNewsAndFeedbackView}
            />
          )}
          <NewHeader
            baseUrl={getAccountsBaseUrl()}
            userConfig={userConfig}
            locale={locale}
            onSwitchLocale={onSwitchLocale}
            localesMap={localesMap}
            authServer={config.authServer}
            onSearch={onSearch}
            initialSearch={queryString}
            isAccounts={false}
            withInternetExplorerWarning
          />
          <NewSidebar
            authorities={authorities}
            accountsBaseUrl={getAccountsBaseUrl()}
            isAccounts={false}
            items={sidebarMenuItems(config?.userConfig)}
            userConfig={userConfig}
            openFeatureBaseFn={openNewsAndFeedbackView}>
            <PartnerRelationSelector />
            {children}
          </NewSidebar>
        </Fragment>
      ) : (
        <Fragment>{children}</Fragment>
      )}
    </MonitorLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  config: shapes.userConfig.isRequired,
  monitorConfig: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  location: locationShape.isRequired,
  history: historyShape.isRequired
};

const mapStateToProps = ({config, locales, monitorConfig}) => ({
  config,
  locale: locales.locale,
  monitorConfig
});

export default withRouter(connect(mapStateToProps)(Layout));
