import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';

// the ancient CustomEvent library (a transitive dependency of the textarea
// autocomplete library that we use) relies on having `global` defined
window.global ||= window;

function prepare() {
  const shouldMockMonitor = !!localStorage.getItem('mockMonitorApi');
  const shouldMockElena = !!localStorage.getItem('mockElenaApi');

  if (
    // @ts-expect-error TODO
    import.meta.env.MODE === 'development' &&
    (shouldMockMonitor || shouldMockElena)
  ) {
    return import('./mocks/browser').then(({worker}) => {
      return worker.start({
        // TODO: make configurable
        onUnhandledRequest: 'bypass'
      });
    });
  }

  return Promise.resolve();
}

function render() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const container = document.getElementById('root')!;
  const root = createRoot(container);
  root.render(<App />);
}

prepare()
  .then(() => {
    render();
  })
  .catch(error => {
    console.error(error);
  });
